@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&family=Neucha&display=swap');

body {
  overscroll-behavior-y: contain;
  user-select: none;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  font-family: 'Merienda', cursive, sans-serif;
  font-size: calc(10px + 2vmin);
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("./backgrounds/forest-with-outlines.png");
  background-size: 104vw;
}

.settings-button {
  position: absolute;
  left: 1.8519vw;
  top: 1.8519vw;
  width: 13.8889vw;
  height: 13.8889vw;
  background-image: url("./icons/settings.png");
  background-size: 9.2593vw;
  background-repeat: no-repeat;
  background-position: center center;
  border: 0.5556vw solid white;
  border-radius: 15vw;
}

input.squirrel-name {
  position: absolute;
  left: 24.3519vw;
  top: 3.7037vw;
  width: 50.9259vw;
  height: 9.2593vw;
  border: 0 none;
  background-color: transparent;
  color: white;
  font-size: 9.2593vw;
  text-align: center;
  text-overflow: ellipsis;
  font-family: 'Merienda', cursive, sans-serif;
}

input[disabled].squirrel-name {
  color: #A8A8A8;
}

.score-box {
  position: absolute;
  right: 1.8519vw;
  width: 17.7778vw;
  height: 7.4074vw;
  border-width: 0.3704vw;
  border-style: solid;
  border-color: white white white transparent;
  border-radius: 2.3148vw;
  padding: 0.9259vw;
  margin-left: 4.1667vw;
  font-size: 5.5556vw;
  text-align: right;
}

.score-box > .icon {
  position: absolute;
  top: calc((100% - 8.3333vw) / 2);
  left: -3.7037vw;
  width: 8.3333vw;
  height: 8.3333vw;
  background-size: 8.3333vw;
}

.score-box.coins {
  top: 1.8519vw;
}

.coin-icon, .score-box.coins > .icon {
  background-image: url("./icons/coin.png");
  border-radius: 4.1667vw;
}

.score-box.gems {
  top: 13.8889vw;
}

.gem-icon, .score-box.gems > .icon, .squirrel-lock > button > span {
  background-image: url("./icons/gem.png");
}

.pulse-plus {
  animation: pulse-plus-animation .5s 2 ease-in-out;
}

.pulse-minus {
  animation: pulse-minus-animation .5s 1 ease-in-out;
}

@keyframes pulse-plus-animation {
  0% {
    transform: scale(100%);
  }
  50% {
    transform: scale(115%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes pulse-minus-animation {
  0% {
    transform: scale(100%);
  }
  50% {
    transform: scale(85%);
  }
  100% {
    transform: scale(100%);
  }
}

.level-and-xp {
  position: absolute;
  bottom: 3.704vw;
  right: 1.852vw;
}

.level-and-xp > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.level-and-xp-fill {
  background-image: url('./icons/heart-fill.png');
}

.level-and-xp-outline {
  background-image: url('./icons/heart-outline.png');
}

.level-and-xp-label {
  font-size: 5.5vw;
  line-height: 220%;
  user-select: none;
}

.panel-open > .score-box {
  background-color: black;
  filter: invert();
}

.panel-open > .score-box > .icon {
  background-color: black;
}

.change-squirrel-arrow {
  position: absolute;
  top: calc(50% - 4.6296vw);
  width: 9.2593vw;
  height: 9.2593vw;
  background-image: url("./icons/arrow.png");
  background-size: 9.2593vw 9.2593vw;
}

.change-squirrel-arrow.next {
  right: 0;
}

.change-squirrel-arrow.previous {
  left: 0;
  transform: scaleX(-1);
}

.open-shop-button, .open-food-button, .open-tricks-button {
  position: absolute;
  bottom: 1.8519vw;
  width: 18.5185vw;
  height: 18.5185vw;
  background-size: 18.5185vw;
}

.open-shop-button {
  left: 1.8519vw;
  background-image: url("./icons/shop.png");
}

.open-food-button {
  left: 30%;
  background-image: url("./icons/food.png");
}

.open-tricks-button {
  left: 54%;
  background-image: url("./icons/tricks.png");
}

.panel {
  position: absolute;
  left: 6vw;
  top: 8vw;
  width: calc(100vw - 12vw);
  height: calc(100vh - 16vw);
  background-color: white;
  color: black;
  border: 0.55vw solid black;
  border-radius: 9.26vw;
  font-size: 5vw;
}

/* Panel heading */
h1 {
  font-size: 10vw;
  margin: 3vw 2vw 1vw 2vw;
  padding: 0;
}

h2 {
  font-size: 8vw;
  margin: 6vw 0 1vw 0;
}

div.panel-viewport {
  width: 100%;
  height: calc(100vh - 54vw);
  overflow-y: auto;
}

table.panel-content {
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

table.panel-content td {
  margin: 0;
  padding: 0;
  border: 5vw solid transparent;
  border-top-width: 0;
}

table.panel-content p {
  text-align: left;
  margin: 0;
  padding: 0;
}

.disabled p {
  color: gray;
}

.disabled .coin-icon {
  filter: brightness(40%) invert();
}

.settings-button.disabled,
.change-squirrel-arrow.disabled,
.open-shop-button.disabled,
.open-food-button.disabled,
.open-tricks-button.disabled {
  filter: brightness(66%);
}

.panel-item-icon {
  position: relative;
  background-image: url("./icons/locked.png");
  background-size: 24.0741vw 24.0741vw;
  border: 0.5556vw solid black;
  border-radius: 24.0741vw;
  width: 24.0741vw;
  height: 24.0741vw;
}

.panel-item-disabled-label {
  line-height: 24.0741vw;
  color: white;
  font-family: monospace;
  font-size: 14vw;
  font-weight: bold;
}

.panel-tricks .panel-item-disabled-label {
  font-size: 6vw;
}

.disabled .panel-item-icon {
  background-color: darkgray;
  filter: grayscale(1) opacity(.6);
}

.panel-item-badge {
  position: absolute;
  bottom: -0.5556vw;
  right: -0.5556vw;
  width: 6.4815vw;
  height: 6.4815vw;
  border: 0.5556vw solid black;
  border-radius: 3.7963vw;
  background-color: rgb(201, 97, 91);
  font-size: 4.6296vw;
}

button.close {
  position: absolute;
  bottom: -5.6vw;
  left: calc(50% - 9.25vw);
  width: 18.5vw;
  height: 18.5vw;
  background-color: rgb(239, 239, 239);
  border: 0.56vw solid black;
  border-radius: 9.25vw;
  font-size: 11vw;
}

button.close::before {
  content: "\d7";
}

.level-info {
  position: absolute;
  left: 6vw;
  width: 78vw;
  top: calc(50vh - 35.5vw);
  height: 60vw;
  background-color: white;
  color: black;
  border: 0.55vw solid black;
  border-radius: 9.26vw;
  padding: 5vw;
}

.level-info.level-up {
  top: calc(50vh - 44.5vw);
  height: 78vw;
}

.level-heart-left, .level-heart-right {
  position: absolute;
  top: 4vw;
  width: 10vw;
  height: calc(574 / 638 * 10vw);
  background-size: 10vw;
  color: black;
  line-height: 8vw;
}

.level-heart-left, .level-up .level-heart-right {
  background-image: url("./icons/heart-outline-black.png"), url("./icons/heart-fill.png");
}

.level-heart-right {
  right: 0;
  background-image: url("./icons/heart-outline-black.png"), url("./icons/heart-fill-white.png");
}

.progress-bar-container {
  position: relative;
  height: 11vw;
}

.progress-bar-container > p {
  margin: 0;
  font-size: 4vw;
}

.progress-bar {
  position: absolute;
  top: 6vw;
  left: 8vw;
  width: 61vw;
  height: 3vw;
  border: .5vw solid black;
}

.squirrel-location {
  position: absolute;
  top: 26.8519vw;
  left: 6.4815vw;
}

.panel-content .coin-icon, .panel-content .gem-icon,
.reward > .coin-icon, .reward > .gem-icon {
  display: inline-block;
  background-size: 6.5vw;
  vertical-align: sub;
  filter: invert();
  color: white;
  padding-left: 8vw;
  background-repeat: no-repeat;
  background-position-y: 0.4vw;
  font-size: 5vw;
}

.rewards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vw;
}

.reward {
  display: inline-block;
}

.reward + .reward {
  margin-left: 8vw;
}

.reward-item {
  border: 0.5vw solid black;
  border-radius: 18vw;
  width: 18vw;
  height: 18vw;
  background-size: 18vw;
  background-repeat: no-repeat;
}

.reward .coin-icon, .reward .gem-icon {
  background-size: 8vw;
  font-size: 6vw;
  padding-left: 9vw;
}

.modal-shim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0;
}

.trick-tracer {
  position: absolute;
  top: calc((100vh - 87.5vw) / 2);
  left: 10vw;
}
.trick-tracer > * {
  width: 80vw;
  height: 87.5vw;
}
.trick-tracer > div {
  position: absolute;
  top: 0;
  left: 0;
  background-size: 80vw;
  background-repeat: no-repeat;
  background-position: center;
  touch-action: none;
}

.draggable-food {
  position: absolute;
  left: 32vw;
  bottom: 12vw;
  width: 36vw;
  height: 36vw;
  background-repeat: no-repeat;
  background-position: center;
  touch-action: none;
}

label {
  display: block;
  position: relative;
  font-size: 4vw;
  text-align: left;
  padding: 2vw 20vw;
}

label > input {
  position: absolute;
  left: 38vw;
  width: 24vw;
  height: 5.5vw;
}

label > input[type="number"], button {
  font-size: 4vw;
  font-family: 'Merienda', cursive, sans-serif;
  border: 0.3vw solid black;
  border-radius: 2vw;
}

label > input[type="number"] {
  text-align: right;
}

label > input[type="range"] {
  width: 20vw;
}

label > span {
  position: absolute;
  display: inline-block;
  left: 59vw;
  width: 4vw;
  height: 4vw;
  text-align: right;
}

button {
  background-color: rgb(239, 239, 239);
  margin: 2vw;
}

.tutorial-edit-name,
.tutorial-unlock-squirrels,
.tutorial-pet-squirrel,
.tutorial-bottom-toolbar {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
  pointer-events: none;
  opacity: 0;
  animation: reveal-tutorial-item .7s 1 ease-out forwards;
}

.tutorial-edit-name {
  left: 33vw;
  top: 13vw;
  background-image: url("./tutorial/edit-name.png");
  width: calc(242 / 756 * 100vw);
  height: calc(210 / 756 * 100vw);
}

.tutorial-unlock-squirrels {
  right: 2vw;
  top: 51vh;
  background-image: url("./tutorial/unlock-squirrels.png");
  width: calc(203 / 756 * 100vw);
  height: calc(216 / 756 * 100vw);
  animation-delay: 1s;
}

.tutorial-pet-squirrel {
  left: 19vw;
  top: 77vw;
  background-image: url("./tutorial/pet-squirrel.png");
  width: calc(479 / 756 * 100vw);
  height: calc(552 / 756 * 100vw);
  animation-delay: .5s;
}

.tutorial-bottom-toolbar {
  left: 0;
  bottom: 15vw;
  background-image: url("./tutorial/bottom-toolbar.png");
  width: calc(756 / 756 * 100vw);
  height: calc(269 / 756 * 100vw);
  animation-delay: 1.5s;
}

@keyframes reveal-tutorial-item {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

.squirrel-tap {
  background-image: url("./animations/tap/tap-sheet-720x720.png");
}

.food-hazelnut {
  background-image: url("./icons/food/hazelnut.png");
}
.food-walnut, .food-walnut-1 {
  background-image: url("./icons/food/walnut.png");
}
.food-carrot-pieces {
  background-image: url("./icons/food/carrot-pieces.png");
}
.food-apple-pieces {
  background-image: url("./icons/food/apple-pieces.png");
}
.food-sunflower-seeds {
  background-image: url("./icons/food/sunflower-seeds.png");
}

.food-hazelnut-1  {
  background-image: url("./feeding/hazelnut/hazelnut-1.png");
}

.food-walnut-1.shell-falling {
  background-image: url("./feeding/walnut/walnut-shell.png");
}

.food-sunflower-seeds-1 {
  background-image: url("./feeding/sunflower-seeds/sunflower-seed-1.png");
}
.food-sunflower-seeds-2 {
  background-image: url("./feeding/sunflower-seeds/sunflower-seed-2.png");
}
.food-sunflower-seeds-3 {
  background-image: url("./feeding/sunflower-seeds/sunflower-seed-3.png");
}
.food-sunflower-seeds-4 {
  background-image: url("./feeding/sunflower-seeds/sunflower-seed-4.png");
}
.food-sunflower-seeds-5 {
  background-image: url("./feeding/sunflower-seeds/sunflower-seed-5.png");
}
.food-sunflower-seeds-6 {
  background-image: url("./feeding/sunflower-seeds/sunflower-seed-6.png");
}
.food-sunflower-seeds-7 {
  background-image: url("./feeding/sunflower-seeds/sunflower-seed-7.png");
}
.food-sunflower-seeds-8 {
  background-image: url("./feeding/sunflower-seeds/sunflower-seed-8.png");
}

.food-apple-pieces-1 {
  background-image: url("./feeding/apple/apple-1.png");
}
.food-apple-pieces-2 {
  background-image: url("./feeding/apple/apple-2.png");
}
.food-apple-pieces-3 {
  background-image: url("./feeding/apple/apple-3.png");
}
.food-apple-pieces-4 {
  background-image: url("./feeding/apple/apple-4.png");
}
.food-apple-pieces-5 {
  background-image: url("./feeding/apple/apple-5.png");
}
.food-apple-pieces-6 {
  background-image: url("./feeding/apple/apple-6.png");
}
.food-apple-pieces-7 {
  background-image: url("./feeding/apple/apple-7.png");
}

.food-carrot-pieces-1 {
  background-image: url("./feeding/carrot/carrots-1.png");
}
.food-carrot-pieces-2 {
  background-image: url("./feeding/carrot/carrots-2.png");
}
.food-carrot-pieces-3 {
  background-image: url("./feeding/carrot/carrots-3.png");
}


.trick-jump-onto {
  background-image: url("./icons/tricks/jump-onto.png");
}
.trick-jump-over {
  background-image: url("./icons/tricks/jump-over.png");
}
.trick-jump-through {
  background-image: url("./icons/tricks/jump-through.png");
}
.trick-wag-tail {
  background-image: url("./icons/tricks/wag-tail.png");
}
.trick-stretch {
  background-image: url("./icons/tricks/stretch.png");
}

.trick-jump-onto-trace-outline {
  background-image: url("./tricks/jump-onto-outline.png");
  filter: invert();
}
.trick-jump-over-trace-outline {
  background-image: url("./tricks/jump-over-outline.png");
  filter: invert();
}
.trick-jump-through-trace-outline {
  background-image: url("./tricks/jump-over-outline.png");
  filter: invert();
}
.trick-wag-tail-trace-outline {
  background-image: url("./tricks/wag-tail-outline.png");
  filter: invert();
}
.trick-stretch-trace-outline {
  background-image: url("./tricks/stretch-outline.png");
  filter: invert();
}

.squirrel-locked {
  background-color: black;
  opacity: 30%;
}

.squirrel-lock {
  position: absolute;
  left: 0;
  top: calc(50vh - 50vw);
  width: 100vw;
  height: 100vw;
  background-image: url("./icons/locked.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.squirrel-lock button {
  margin-top: 49vw;
  width: 40vw;
  border: 0.5vw solid white;
  padding: 3vw 0.5vw;
  background-color: black;
  color: white;
  font-size: 7vw;
}

.squirrel-lock > button > .gem-icon {
  display: inline-block;
  width: 8vw;
  height: 8vw;
  vertical-align: sub;
  margin: 0 .4vw;
  background-repeat: no-repeat;
  background-size: 100%;
}

.squirrel-lock > button[disabled] {
  opacity: 40%;
}
